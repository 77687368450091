// 
// user.js
// Use this to write your custom JS
//

var menuItemTemplate = require("../handlebars/menu-item.handlebars");
var menuCategoryTemplate = require("../handlebars/menu-category.handlebars")
var menuCategoryContentTemplate = require('../handlebars/menu-category-content.handlebars');

$.fn.doOnce = function (func) {
    this.length && func.apply(this);
    return this;
}

var settings = {
    isOpen: false,
    geoLocation: {
        latitude: 33.3002103,
        longitude: -111.8419838
    },
    locationName: undefined,
    locationUrl: undefined,
    showPricing: true
};

var initMaps = function (id) {
    var position = [settings.geoLocation.longitude, settings.geoLocation.latitude];

    var popupTemplate = '<div class="customInfobox"><div class="name">{name}</div>{url}</div>';
    var popup = new atlas.Popup({
        pixelOffset: [0, -35],
        closeButton: false
    });
    var map = new atlas.Map(id, {
        center: position,
        zoom: 13,
        style: "night",
        view: 'Auto',
        language: 'en-US',
        authOptions: {
            authType: 'subscriptionKey',
            subscriptionKey: mapsKey
        }
    });

    if (settings.locationName === undefined) {
        return;
    }

    map.events.add('ready', function () {
        var dataSource = new atlas.source.DataSource();
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
            iconOptions: {
                image: 'truck-icon',
                size: 1
            }
        });

        map.sources.add(dataSource);

        map.controls.add(new atlas.control.ZoomControl(), {
            position: 'bottom-right'
        });

        map.imageSprite.add('truck-icon', '/assets/img/truck-icon.png').then(function () {
            dataSource.add(new atlas.data.Feature(new atlas.data.Point(position), null));

            map.layers.add(symbolLayer);
        });

        if (settings.locationName !== undefined) {
            popup.setOptions({
                content: popupTemplate.replace(/{name}/g, settings.locationName).replace(/{url}/g, settings.locationUrl),
                position: position
            });
        }

        popup.open(map);
    });
};

$.ajax({
    url: apiUrl + "/api/Settings",
    headers: { "ApiKey": apiKey }
}).done(function (data) {
    settings.isOpen = data.isOpen;
    settings.showPricing = data.showPricing;

    if (settings.isOpen && data.geoLocation.latitude !== undefined && data.geoLocation.longitude !== undefined) {
        settings.geoLocation = data.geoLocation;
        settings.locationName = data.locationName;

        if ((navigator.platform.indexOf("iPhone") != -1) || (navigator.platform.indexOf("iPod") != -1) || (navigator.platform.indexOf("iPad") != -1)) {
            settings.locationUrl = `<a class="text-secondary" href="maps://www.google.com/maps/dir/?api=1&destination=${settings.geoLocation.latitude},${settings.geoLocation.longitude}" target="_blank">Navigate to the truck</a>`
        } else {
            settings.locationUrl = `<a class="text-secondary" href="https://www.google.com/maps/dir/?api=1&destination=${settings.geoLocation.latitude},${settings.geoLocation.longitude}" target="_blank">Navigate to the truck</a>`
        }
    }
   
    $('#map').doOnce(function () {
        initMaps('map');
    });

    $('#menuContent').doOnce(function () {
        $.ajax({
            url: apiUrl + "/api/MenuCategories",
            headers: { "ApiKey": apiKey }
        }).done(function (categories) {
            var enabledCategories = categories.filter(obj => obj.enabled === true);
            $('#menuTabs').append(menuCategoryTemplate(enabledCategories)).children(":first").addClass('active');

            $('#menuContent').html(menuCategoryContentTemplate(enabledCategories)).children(":first").addClass('active');

            $.ajax({
                url: apiUrl + "/api/MenuItems",
                headers: { "ApiKey": apiKey }
            }).done(function (menuItems) {
                enabledCategories.forEach(category => {
                    var enabledMenuItems = menuItems.filter(obj => obj.enabled === true && obj.categoryId == category.id);
                    if (enabledMenuItems.length > 0) {
                        var selector = "#cat-" + category.id + "-data";
                        $(selector).html(menuItemTemplate(enabledMenuItems));
                    }
                });

                if (settings.showPricing === false) {
                    $('.pricing').remove();
                }
            });
        });
    });
});
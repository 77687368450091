var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":15}}}) : helper)))
    + "\" class=\"event row\">\n    <div class=\"border-top\">\n        <div class=\"container\">\n            <div class=\"row align-items-center py-4\">\n                <!-- Date -->\n                <div class=\"col-3\">\n                    <time class=\"text-center\" datetime=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"start") || (depth0 != null ? lookupProperty(depth0,"start") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start","hash":{},"data":data,"loc":{"start":{"line":8,"column":56},"end":{"line":8,"column":65}}}) : helper)))
    + "\">\n                        <div class=\"mb-2 display-6 font-serif\">"
    + alias4(__default(require("../js/helpers/formatDate.js")).call(alias1,"D",(depth0 != null ? lookupProperty(depth0,"start") : depth0),{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":9,"column":63},"end":{"line":9,"column":87}}}))
    + "</div>\n                        <div>"
    + alias4(__default(require("../js/helpers/formatDate.js")).call(alias1,"mmm",(depth0 != null ? lookupProperty(depth0,"start") : depth0),{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":10,"column":29},"end":{"line":10,"column":55}}}))
    + "</div>\n                    </time>\n                </div>\n\n                <div class=\"col-9\">\n                    <!-- Title -->\n\n                    <div class=\"row\">\n                        <div class=\"text-center mb-2 h5\">\n                            "
    + alias4(__default(require("../js/helpers/formatDate.js")).call(alias1,"dddd",(depth0 != null ? lookupProperty(depth0,"start") : depth0),{"name":"formatDate","hash":{},"data":data,"loc":{"start":{"line":19,"column":28},"end":{"line":19,"column":55}}}))
    + "\n                        </div>\n                    </div>\n\n                    <div class=\"row\">\n                        <div class=\"h5 m-0 text-center \">\n                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":25,"column":37}}}) : helper)))
    + "\n                        </div>\n                    </div>\n\n                    <!-- Date/Time -->\n                    <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAllDay") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":35,"column":35}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n\n        </div>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <time class=\"h4 m-0 font-serif text-center \" datetime=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"start") || (depth0 != null ? lookupProperty(depth0,"start") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"start","hash":{},"data":data,"loc":{"start":{"line":32,"column":79},"end":{"line":32,"column":88}}}) : helper)))
    + "\">\n                            "
    + alias2(__default(require("../js/helpers/formatTime.js")).call(alias1,"hh:mm TT",(depth0 != null ? lookupProperty(depth0,"start") : depth0),{"name":"formatTime","hash":{},"data":data,"loc":{"start":{"line":33,"column":28},"end":{"line":33,"column":59}}}))
    + " - "
    + alias2(__default(require("../js/helpers/formatTime.js")).call(alias1,"hh:mm TT",(depth0 != null ? lookupProperty(depth0,"end") : depth0),{"name":"formatTime","hash":{},"data":data,"loc":{"start":{"line":33,"column":62},"end":{"line":33,"column":91}}}))
    + "\n                        </time>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":43,"column":9}}})) != null ? stack1 : "");
},"useData":true});
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

if (environment == 'production' && appInsightsInstrumentationKey) {
    const appInsights = new ApplicationInsights({config: {
        instrumentationKey: appInsightsInstrumentationKey
    }});
    
    appInsights.loadAppInsights();
    appInsights.trackPageView();
}

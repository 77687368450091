//
// events.js
//

var eventItemTemplate = require('../handlebars/event.handlebars');

$.fn.doOnce = function (func) {
    this.length && func.apply(this);
    return this;
}

const take = 10;
var skip = 0;

 // if ((navigator.platform.indexOf("iPhone") != -1) || (navigator.platform.indexOf("iPod") != -1) || (navigator.platform.indexOf("iPad") != -1)) {
    //     $('a.directions').attr('href', `maps://www.google.com/maps/dir/?api=1&destination=${settings.geoLocation.latitude},${settings.geoLocation.longitude}`);
    // } else {
    //     $('.directions').attr('href', `https://www.google.com/maps/dir/?api=1&destination=${settings.geoLocation.latitude},${settings.geoLocation.longitude}`);
    // }

    
const loadEvents = function () {
    $.ajax({
        url: apiUrl + `/api/Events?skip=${skip}&take=${take}`,
        headers: { "ApiKey": apiKey }
    }).done(function (events) {
        $('.loader').addClass("d-none");

        $('#events').append(eventItemTemplate(events));

        events.forEach(function (event) {
            animationObserver.observe(document.getElementById(event.id));
        });

        if (events.length < take)
            return;

        skip += take;

        lastCardObserver.observe(document.querySelector('.event:last-child'));
    });
};

const animationObserver = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        entry.target.classList.toggle('show', entry.isIntersecting);
    });
}, { threshold: .5 });

const lastCardObserver = new IntersectionObserver(entries => {
    const lastCard = entries[0];

    if (!lastCard.isIntersecting)
        return;

    loadEvents();
    lastCardObserver.unobserve(lastCard.target);
}, { rootMargin: '100px' });

$('#events').doOnce(function () {
    loadEvents();
});


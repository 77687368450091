//
// contact.js
//

import { toggleNotification } from './helpers';
import 'inputmask';

$('#phoneNumber').inputmask('(999) 999-9999');

$('#contactForm').on("submit", function(e) {
  e.preventDefault();

  var form = $(this); 
  var data = { 
    fullName: $('#fullName').val(),
    phoneNumber: $('#phoneNumber').val(),
    emailAddress: $('#emailAddress').val(),
    message: $('#message').val(),
    subject: $('#subject').val()
  };

  $.ajax({
      url: apiUrl + "/api/Contact",
      data: JSON.stringify(data),
      method: "POST",
      headers: { "ApiKey": apiKey },
      contentType: "application/json; charset=utf-8"
  }).done(function(data) {
      form[0].reset();
      toggleNotification('You contact request has been sent. We will reply as soon as we get a chance!');
  }).fail(function (data){
      toggleNotification('There was an issue sending your request. Please try again later or give us a call.');
  });    
});
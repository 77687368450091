module.exports = function (format, dateTime) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const weekdayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dt = new Date(dateTime);

    if (format === 'D')
        return dt.getDate();


    if (format === 'mmm')
        return monthNames[dt.getMonth()];


    if (format === 'dddd')
        return weekdayNames[dt.getDay()];

    return null;
};